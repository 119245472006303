import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Cross as Hamburger } from 'hamburger-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { Link, animateScroll as scroll } from "react-scroll";
import Home from './pages/home/Home';
import './App.css';

function App() {

  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);
  const [isHamburgerOpen, setIsHamburgerOpen] = useState(false);
  const [scrollToTopHidden, setScrollToTopHidden] = useState(true);

  const updateScrollToTop = () => {
    if(window.scrollY > 150) {
      setScrollToTopHidden(false);
    } else {
      setScrollToTopHidden(true);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", updateScrollToTop);
    return () => {
      window.removeEventListener("scroll", updateScrollToTop);
    }
  });

  return (
    <>
      <div id="scroll-to-top" hidden={scrollToTopHidden} onClick={() => scroll.scrollToTop()}>
        <FontAwesomeIcon icon={faArrowAltCircleUp} style={{ fontSize: "40px" }} color="black" />
      </div>
      <Navbar expand="lg" fixed="top" expanded={isNavbarExpanded}>
        <Navbar.Toggle aria-controls="basic-navbar-nav" onClick={() => setIsNavbarExpanded(!isNavbarExpanded)}>
          <Hamburger color="white" toggled={isHamburgerOpen} toggle={setIsHamburgerOpen}></Hamburger>
        </Navbar.Toggle>
        <Navbar.Collapse>
          <Nav>
            <Link
              className="nav-links"
              to="about-section"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onClick={() => {
                setIsNavbarExpanded(false);
                setIsHamburgerOpen(false);
              }
              }
            >About Me</Link>
            <Link
              className="nav-links"
              to="projects-section"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onClick={() => {
                setIsNavbarExpanded(false);
                setIsHamburgerOpen(false);
              }
              }
            >Projects</Link>
            <Link
              className="nav-links"
              to="interests-section"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onClick={() => {
                setIsNavbarExpanded(false);
                setIsHamburgerOpen(false);
              }
              }
            >Interests</Link>
            <Link
              className="nav-links"
              to="contact-section"
              spy={true}
              smooth={true}
              offset={-80}
              duration={500}
              onClick={() => {
                setIsNavbarExpanded(false);
                setIsHamburgerOpen(false);
              }
              }
            >Contact</Link>
          </Nav>
        </Navbar.Collapse>
        <div className={isNavbarExpanded ? "socials-container expanded" : "socials-container"}>
          <div id="linkedin-container">
            <a href="https://www.linkedin.com/in/siraaj-fadoo/" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faLinkedinIn} style={{ fontSize: "40px" }} color="white" /></a>
          </div>
          <div id="email-container">
            <a href="mailto:siraaj.fadoo@gmail.com" target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faEnvelope} style={{ fontSize: "40px" }} color="white" /></a>
          </div>
        </div>
      </Navbar>
      <Router>
        <Switch>
          <Route path="/">
            <Home />
          </Route>
        </Switch>
      </Router>
    </>
  );
}

export default App;
