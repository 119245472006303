import Title from './title/Title.js';
import About from './about/About.js';
import Projects from './projects/Projects.js';
import Interests from './interests/Interests.js';
import Contact from './contact/Contact.js';


function Home() {
  return (
    <>
      <Title />
      <About />
      <Projects />
      <Interests />
      <Contact />
    </>
  );
}

export default Home;
