import { Col, Container, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './Interests.css';

function Interests() {
  return (
    <div id="interests-section">
      <Container fluid>
        <Row>
          <Col className="interests-col" md={{ span: 8, offset: 2 }} offset={4}>
            <ScrollAnimation animateIn="zoomIn">
              <h1 id="interests-heading">Interests</h1>
              <div id="interests-list">
                <ul>
                  <li>Music</li>
                  <li>Film</li>
                  <li>Cars</li>
                  <li>Sports</li>
                  <li>Video Games</li>
                  <li>Politics</li>
                  <li>Sneakers</li>
                </ul>
              </div>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Interests;