import { Col, Container, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import resume from '../../../assets/pdf/Siraaj_Fadoo_Resume.pdf';
import './Contact.css';

function Contact() {
  return (
    <div id="contact-section">
      <Container fluid>
        <Row>
          <Col className="contact-col" md={{ span: 8, offset: 2 }} offset={4}>
            <ScrollAnimation animateIn="zoomIn">
              <h1 id="contact-heading">Contact</h1>
              <p className="contact-text">Click <a href={resume} target="_blank" rel="noreferrer" download>here</a> for my resume.</p>
              <p className="contact-text">Contact me at <a href="mailto:siraaj.fadoo@gmail.com" target="_blank" rel="noreferrer">siraaj.fadoo@gmail.com</a>!</p>
            </ScrollAnimation>

          </Col>
        </Row>
      </Container>
      <footer id="footer">
        <p id="footer-text">Created by Siraaj Fadoo.</p>
      </footer>
    </div>
  );
}

export default Contact;