import profilePic from '../../../assets/img/profilepic.jpg';
import './Title.css';

function Title() {
  return (
    <div id="title-section">
      <div id="profilepic-container">
        <img id="profilepic" src={profilePic} alt="Profile"></img>
      </div>
    </div>
  );
}

export default Title;