import { Col, Container, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import CountUp from 'react-countup';
import VisibilitySensor from 'react-visibility-sensor';
import rolka1Pic from '../../../assets/img/rolka1.png';
import rolka2Pic from '../../../assets/img/rolka2.png';
import musinfo1Pic from '../../../assets/img/musinfo1.png';
import musinfo2Pic from '../../../assets/img/musinfo2.png';
import emailPhishingToolPic from '../../../assets/img/email-phishing-tool.png';
import fuerzaCoachingPic from '../../../assets/img/fuerza-coaching.png';
import './Projects.css';

function Projects() {
  return (
    <div id="projects-section">
      <Container fluid>
        <Row>
          <Col lg={12} id="projects-heading-col">
            <ScrollAnimation animateIn="zoomIn">
              <h1 id="projects-heading">Projects</h1>
            </ScrollAnimation>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} className="projects-col">
            <ScrollAnimation animateIn="zoomIn">
              <h2 className="projects-subheading">Musinfo</h2>
              <p className="projects-paragraph">I developed an iOS app that displays interesting statistics about the user's music library. I coded this app using Swift. Check it out on the App Store <a href="https://apps.apple.com/us/app/musinfo/id1621808793" target="_blank" rel="noreferrer">here</a>.</p>
            </ScrollAnimation>
          </Col>
          <Col lg={3} sm={12} className="projects-col">
            <ScrollAnimation animateIn="zoomIn">
              <img className="projects-pic" src={musinfo1Pic} width="70%" alt="Musinfo screenshot 1"></img>
            </ScrollAnimation>
          </Col>
          <Col lg={3} sm={12} className="projects-col">
            <ScrollAnimation animateIn="zoomIn">
              <img className="projects-pic" src={musinfo2Pic} width="70%" alt="Musinfo screenshot 2"></img>
            </ScrollAnimation>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} className="projects-col">
            <ScrollAnimation animateIn="zoomIn">
              <h2 className="projects-subheading">Rolka</h2>
              <p className="projects-paragraph">I developed an app that helps teachers take attendance in a classroom. After a student walks into class and scans a QR code on the teacher's device, he or she is marked as present. I built the app using Ionic framework, React.js, and Google Firebase. In addition, I created a set of user stories with acceptance criteria, designed prototype screens, and utilized a UML diagram to format the architecture. Feel free to check it out on the App Store and Google Play at <a href="https://linktr.ee/rolka" target="_blank" rel="noreferrer">linktr.ee/rolka</a>.</p>
            </ScrollAnimation>
          </Col>
          <Col lg={3} sm={12} className="projects-col">
            <ScrollAnimation animateIn="zoomIn">
              <img className="projects-pic" src={rolka1Pic} width="70%" alt="Rolka screenshot 1"></img>
            </ScrollAnimation>
          </Col>
          <Col lg={3} sm={12} className="projects-col">
            <ScrollAnimation animateIn="zoomIn">
              <img className="projects-pic" src={rolka2Pic} width="70%" alt="Rolka screenshot 2"></img>
            </ScrollAnimation>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} className="projects-col">
            <ScrollAnimation animateIn="zoomIn">
              <h2 className="projects-subheading">Email Phishing Tool</h2>
              <p className="projects-paragraph">I developed a Google Chrome Browser extension that detects whether an email is phishing or not. I prototyped the design using Adobe XD. Additionally, it communicates with multiple public APIs. Feel free to check it out on the Chrome Web Store at <a href="http://bit.ly/EmailPhishingTool" target="_blank" rel="noreferrer">bit.ly/EmailPhishingTool</a>.</p>
            </ScrollAnimation>
          </Col>
          <Col lg={6} sm={12} className="projects-col">
            <ScrollAnimation animateIn="zoomIn">
              <img className="projects-pic" src={emailPhishingToolPic} width="90%" alt="Rolka screenshot 2"></img>
            </ScrollAnimation>
          </Col>
        </Row>
        <Row>
          <Col lg={6} sm={12} className="projects-col">
            <ScrollAnimation animateIn="zoomIn">
              <h2 className="projects-subheading">Fuerza Coaching</h2>
              <p className="projects-paragraph">I developed a website for a personal trainer using React.js. The website includes a contact form and is optimized for mobile viewing as well. Check it out at <a href="https://pedantic-mccarthy-2ba4d3.netlify.app/" target="_blank" rel="noreferrer">pedantic-mccarthy-2ba4d3.netlify.app!</a></p>
            </ScrollAnimation>
          </Col>
          <Col lg={6} sm={12} className="projects-col">
            <ScrollAnimation animateIn="zoomIn">
              <img className="projects-pic" src={fuerzaCoachingPic} width="90%" alt="Fuerza coaching screenshot"></img>
            </ScrollAnimation>
          </Col>
        </Row>
        <Row id="stat-banner-row">
          <Col className="stat-banner-col" lg sm={12}>
            <div className="stat-banner-quantity">
              <CountUp end={314} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </div>
            <h4 className="stat-banner-heading">Git Commits</h4>
          </Col>
          <Col className="stat-banner-col" lg sm={12}>
            <div className="stat-banner-quantity">
              <CountUp end={33} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </div>
            <h4 className="stat-banner-heading">Projects Completed</h4>
          </Col>
          <Col className="stat-banner-col" lg sm={12}>
            <div className="stat-banner-quantity">
              <CountUp end={25} redraw={true}>
                {({ countUpRef, start }) => (
                  <VisibilitySensor onChange={start} delayedCall>
                    <span ref={countUpRef} />
                  </VisibilitySensor>
                )}
              </CountUp>
            </div>
            <h4 className="stat-banner-heading">Cups of Coffee</h4>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Projects;