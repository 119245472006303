import { Col, Container, Row } from 'react-bootstrap';
import ScrollAnimation from 'react-animate-on-scroll';
import './About.css';

function About() {
  return (
    <div id="about-section">
      <Container fluid>
        <Row>
          <Col className="about-col" md={{ span: 8, offset: 2 }} offset={4}>
            <ScrollAnimation animateIn="zoomIn">
              <h1 id="about-heading">About Me</h1>
              <p className="about-paragraph">Hi! My name is Siraaj.</p>
              <p className="about-paragraph">I was born in New Jersey, moved to Georgia when I was 3, and have resided in the Greater Atlanta Area ever since. I am passionate about all types of technology, ranging from smartphones and smartwatches to electric cars and drones. I have experience developing websites, mobile applications, and Java programs. I also have experience with SQL, C++, and have worked in an Agile environment.</p>
              <p className="about-paragraph">My favorite types of software development are web and mobile - be it native iOS or Android development or hybrid development using Ionic and Angular.</p>
              <p className="about-paragraph">My strong suits include my ability to communicate effectively with others, listen intently and ask questions when necessary, and collaborate in a team-based environment to create the best product possible.</p>
              <p className="about-paragraph">Overall, I would like to help make people’s lives easier and more fulfilling through software!</p>
            </ScrollAnimation>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default About;